/* =========
  Testing Popups
  A Simple Popup Plugin for Squarespace
  This Code is Licensed by Will-Myers.com
========== */
/*
Default Custom Props
.wm-popup-container {
  --max-width: 900px;
  --max-height: 900px;
  --border-radius: 5px;
  --border: 0px dashed currentColor;
  --blur: 0px;
  --animation: slideup;
  --animation-duration: 0.3s;
  --background-opacity: 0.6;
  
  --close-color: white;
  --close-background: purple;
  --close-size: 1rem;
}*/

.wm-popup-container {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: -1;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s ease,
    visibilitiy 0s ease;
}
.wm-popup-container > * {
  grid-area: 1 / 1;
}
.wm-popup-background {
  height: 100%;
  width: 100%;
  display: grid;
  position: absolute;
  top:0;
  place-items:center;
  transition: background var(--animation-duration, 0.3s), 
    backdrop-filter var(--animation-duration, 0.3s);
  backdrop-filter: blur(var(--blur, 0px)) opacity(0);
}
.wm-popup-wrapper {
  position:relative;
  max-width: clamp(0px, var(--max-width, var(--sqs-site-max-width, 900px)), 90vw);
  width:100%;
  box-shadow: 0px 0px 12px hsla(0,0%,0%,.1);
  box-sizing:border-box;
}
.wm-popup-content {
  max-height:clamp(0px, var(--max-height, 900px), 90vh);
  overflow-y: auto;
  border-radius: var(--border-radius, 5px);
  border:var(--border, initial);
}
.wm-popup-content > * {
  display:none;
}
.single-block-container {
  display:none;
  width:100%;
  padding:17px;
}

/*Close Button*/
.wm-popup-close-btn{
  z-index:1;
  position: absolute;
  top: -1rem;
  right: -1rem;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-content: center;
  border-radius: 50%;
  border: 0;
  color: var(--close-color, var(--primaryButtonTextColor, white));
  background: var(--close-background, var(--primaryButtonBackgroundColor, black));
  padding: 8px;
  line-height: 0;
}
.wm-popup-close-btn svg{
  width: 100%;
  height: 100%;
}

/*Open*/
.wm-popup-container.open {
  z-index:9999;
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: opacity .3s ease,
    visibilitiy 0s ease .3s,
    transform .3s ease;
}
.wm-popup-container.open .wm-popup-background {
  background: hsla(0, 0%, 0%, var(--background-opacity, 0.6));
  backdrop-filter: blur(var(--blur, 0px)) opacity(1);
}
.wm-popup-container.open .wm-popup-content > .open {
  display: block;
}


/*Has Single Block*/
.single-block-only.open .single-block-container {
  display:block;
}
.wm-popup-container .single-block-only.open .content-wrapper > .content {
  display:none;
}
.single-block-only .content-wrapper {
  padding:0 !important;
}
.single-block-only .page-section {
  min-height:0vh !important;
}

/*Sections*/
.wm-popup-container .page-section{
  padding-top: 0px !important;
}

/*Video Sections*/
.single-block-container .sqs-block-video{
  padding:0px;
}
.single-block-container .embed-block-wrapper{
  padding-bottom: 0px !important;
}
.single-block-container .video-player .video-player__icons{
  top:0;
}

/*Remove padding around single block*/
.wm-popup-content > .sqs-block {
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media(min-width: 767px) {
  .wm-popup-content .fluid-engine{
    grid-template-columns: minmax(17px, 1fr) repeat(24, minmax(0, var(--cell-max-width))) minmax(17px, 1fr);
  }
}


/*Info Icon*/
a[data-wm-popup]{
  position:relative;
}
.wm-info-icon{
  position:absolute;
  height: .8rem; 
  width: .8rem; 
  top:0;
  left:0;
}
.wm-info-icon svg{
  position:absolute;
  width:100%;
  height:100%;
}

p .wm-info-icon{
  height: .8rem; 
  width: .8rem; 
}
h1 .wm-info-icon, 
h2 .wm-info-icon{
  height: 1.5rem; 
  width: 1.5rem; 
}
h3 .wm-info-icon, 
h4 .wm-info-icon{
  height: 1.2rem; 
  width: 1.2rem; 
}
